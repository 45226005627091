import {
  Wrapper,
  IntroduceWrapper,
  Title,
  HighLight,
  LearnMoreButton,
  Info,
} from "./style-mobile";
import { Box, Link } from "@mui/material";
import arrowSvg from "../../assets/icon/arrow-white.svg";
import diagram from "../../assets/bg/diagram-frame-five.png";

function FrameFiveMobile() {
  return (
    <Wrapper>
      <Box component="img" src={diagram} mt={3} width={'120%'}></Box>
      <IntroduceWrapper>
        <Title>
          Efficient
          <HighLight> Information Retrieval,</HighLight> Powered by ERC-6150.
        </Title>
        <Info>
          ELS harnesses the power of the{" "}
          <Link
            color="#4773e8"
            href={process.env.REACT_APP_EIP6150}
            target="_blanck"
          >
            ERC-6150
          </Link>{" "}
          protocol and delivers a<br />
          seamless, efficient search experience. Our hierarchical B+ tree-like
          <br />
          structure simplifies information retrieval, allowing users to find
          <br />
          what they need effortlessly.
        </Info>
        <LearnMoreButton onClick={() => window.open(process.env.REACT_APP_DOC)}>
          Learn More
          <Box component="img" src={arrowSvg} ml={0.8} width={"0.8rem"}></Box>
        </LearnMoreButton>
      </IntroduceWrapper>
    </Wrapper>
  );
}

export default FrameFiveMobile;
