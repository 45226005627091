import { React, useState } from "react";
import { experimentalStyled } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import texture from "../assets/bg/texture.png";
import ellipse from "../assets/bg/ellipse.png";
import frameOneBG from "../assets/bg/frame-one.png";
import { Outlet } from "react-router-dom";

const LayoutRoot = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  //   overflow: "hidden",
  width: "100%",
  background: "#fcfcfc",
}));

const FrameOneBG = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${frameOneBG}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
//   height:'100%'
}));

const Texture = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${texture}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "top",
}));

const Ellipse = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${ellipse}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "initial",
}));

const Layout = () => {
  return (
    <LayoutRoot>
      <FrameOneBG
        sx={{
          backgroundSize: location.pathname === "/" ? "100%" : "100% 100%",
        }}
      >
        <Ellipse>
          <Texture>
            <Header />
            <Outlet />
            <Footer />
          </Texture>
        </Ellipse>
      </FrameOneBG>
    </LayoutRoot>
  );
};

export default Layout;
