import { Box, Grid, Link, experimentalStyled } from "@mui/material";

export const Wrapper = experimentalStyled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "30vh 4vw 8vh",
}));

export const Copyright = experimentalStyled(Box)(() => ({
  fontSize: "16px",
  color: "#333333",
}));

export const ContactLinkWrapper = experimentalStyled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ContactLink = experimentalStyled(Link)(() => ({
  display: "flex",
  alignItems: "center",
  color: "#333333",
  fontSize: "16px",
  cursor: "pointer",
  margin: "0 16px",
}));

export const Img = experimentalStyled("img")(() => ({
  marginRight: "10px",
}));
