import {
  Wrapper,
  Title,
  HighLight,
  ButtonWrapper,
  DocButton,
  ProductDisplay,
  ProductDisplayImg,
  ProductDisplayContainer,
  Mask,
  Mask2,
} from "./style";
import { LaunchButton } from "../Header/style";
import { Box, useMediaQuery } from "@mui/material";
import arrowSvg from "../../assets/icon/arrow.svg";
import productDisplay from "../../assets/bg/product-display.png";

function FrameOne() {
  const matches = useMediaQuery("(min-width:600px)");
  const matches2 = useMediaQuery("(max-width:1280px)");

  return (
    <Wrapper>
      <Title>
        <HighLight>Decentralized listings</HighLight> for <br />
        information, services, & more.
      </Title>
      <ButtonWrapper>
        <LaunchButton onClick={() => window.open(process.env.REACT_APP_APP)}>
          Launch App
        </LaunchButton>
        <DocButton onClick={() => window.open(process.env.REACT_APP_DOC)}>
          Read Our Developer Doc
          <Box component="img" src={arrowSvg} ml={0.8}></Box>
        </DocButton>
      </ButtonWrapper>
      <ProductDisplayContainer>
        {matches && matches2 ? (
          <ProductDisplayImg src={productDisplay} />
        ) : (
          <ProductDisplay src={process.env.REACT_APP_APP_PREVIEW_DASHBOARD} />
        )}
        <Mask />
        <Mask2 />
      </ProductDisplayContainer>
    </Wrapper>
  );
}

export default FrameOne;
