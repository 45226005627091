import { Wrapper, Title, HighLight } from "./style";
import { Box } from "@mui/material";
import diagram from "../../assets/bg/diagram-frame-two.png";

function FrameTwo() {
  return (
    <Wrapper>
      <Title>
        A Decentralized , Web3
        <HighLight> Marketplace.</HighLight>
      </Title>
      <Box component="img" src={diagram} mt={3} width={1164}></Box>
    </Wrapper>
  );
}

export default FrameTwo;
