import { React, useState } from "react";
import { experimentalStyled, Box } from "@mui/material";
import Header from "../components/Header/HeaderMobile";
import FrameOneMobile from "../components/FrameOne/FrameOneMobile";
import FrameTwoMobile from "../components/FrameTwo/FrameTwoMobile";
import FrameThreeMobile from "../components/FrameThree/FrameThreeMobile";
import FrameFourMobile from "../components/FrameFour/FrameFourMobile";
import FrameFiveMobile from "../components/FrameFive/FrameFiveMobile";
import FrameSixMobile from "../components/FrameSix/FrameSixMobile";
import RoadMapMobile from "../components/RoadMap/RoadMapMobile";
import ContactMobile from "../components/Contact/ContactMobile";
import texture from "../assets/bg/texture.png";
import ellipse from "../assets/bg/ellipse.png";
import frameOneBG from "../assets/bg/frame-one.png";
import leftEllipse from "../assets/bg/ellipse-left.png";
import rightEllipse from "../assets/bg/ellipse-right.png";

const LandingMobileRoot = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const FrameOneMobileBG = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${frameOneBG}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
}));

const Texture = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${texture}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "top",
}));

const Ellipse = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${ellipse}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "initial",
  backgroundPosition: "-30% -10%",
}));

const LeftEllipse = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${leftEllipse}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
}));

const RightEllipse = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${rightEllipse}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "right",
}));

const LandingMobile = () => {
  return (
    <LandingMobileRoot>
      <FrameOneMobile />
      <FrameTwoMobile />
      <RightEllipse>
        <FrameThreeMobile />
      </RightEllipse>
      <LeftEllipse>
        <FrameFourMobile />
      </LeftEllipse>
      <RightEllipse>
        <FrameFiveMobile />
      </RightEllipse>
      <LeftEllipse>
        <FrameSixMobile />
      </LeftEllipse>
      <RoadMapMobile />
      <ContactMobile />
    </LandingMobileRoot>
  );
};

export default LandingMobile;
