import { Wrapper, Title } from "./style";
import { Box, Link } from "@mui/material";
import roadMap from "../../assets/bg/road-map.svg";

function RoadMapMobile() {
  return (
    <Wrapper>
      <Title>Road Map</Title>
      <Box component="img" src={roadMap} mt={3} width={"85%"}></Box>
    </Wrapper>
  );
}

export default RoadMapMobile;
