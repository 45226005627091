import {
  Wrapper,
  IntroduceWrapper,
  Title,
  HighLight,
  LearnMoreButton,
  Info,
} from "./style-mobile";
import { Box } from "@mui/material";
import arrowSvg from "../../assets/icon/arrow-white.svg";
import diagram from "../../assets/bg/diagram-frame-four.png";

function FrameFourMobile() {
  return (
    <Wrapper>
      <Box component="img" src={diagram} mt={3} width={"85%"}></Box>
      <IntroduceWrapper>
        <Title>
          Say <HighLight>Goodbye to Scams and Malicious</HighLight> Content.
        </Title>
        <Info>
          Our staking mechanism and community oversights ensure the
          <br /> quality of posts and reduce the number of malicious activities.
          <br />
          With that, you can read and post ads on the ELS marketplace
          <br /> feeling secured, confident and reliable.
        </Info>
        <LearnMoreButton onClick={() => window.open(process.env.REACT_APP_DOC)}>
          Learn More
          <Box component="img" src={arrowSvg} ml={0.8} width={"0.8rem"}></Box>
        </LearnMoreButton>
      </IntroduceWrapper>
    </Wrapper>
  );
}

export default FrameFourMobile;
