import {
  Wrapper,
  IntroduceWrapper,
  Title,
  HighLight,
  LearnMoreButton,
  Info,
} from "./style-mobile";
import { Box } from "@mui/material";
import arrowSvg from "../../assets/icon/arrow-white.svg";
import diagram from "../../assets/bg/diagram-frame-three.png";

function FrameThreeMobile() {
  return (
    <Wrapper>
      <Box component="img" src={diagram} mt={3} width={"85%"}></Box>
      <IntroduceWrapper>
        <Title>
          The first marketplace to <HighLight>publish,</HighLight>
          <br />
          <HighLight>share, and discover</HighLight> information with true{" "}
          <HighLight>freedom.</HighLight>
        </Title>
        <Info>
          Unparalleled information freedom in a decentralized <br /> environment
          - effortlessly publish, share, and discover diverse <br />
          content, from jobs and services to goods and events, all in a <br />
          secure and transparent platform.
        </Info>
        <LearnMoreButton onClick={() => window.open(process.env.REACT_APP_DOC)}>
          Learn More
          <Box component="img" src={arrowSvg} ml={0.8} width={"0.8rem"}></Box>
        </LearnMoreButton>
      </IntroduceWrapper>
    </Wrapper>
  );
}

export default FrameThreeMobile;
