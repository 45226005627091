import { Button, Link, experimentalStyled } from "@mui/material";

export const Wrapper = experimentalStyled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  // height: 0,
  padding: "26px 30px",
}));

export const LogoWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  width: "fit-content",
  height: "fit-content",
  whiteSpace: "nowrap",
}));

export const LogoTitle = experimentalStyled("div")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "14px",
  lineHeight: "24px",
}));

export const FeatureWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  height: "fit-content",
}));

export const TabLink = experimentalStyled(Link)(() => ({
  textDecoration: "unset",
  margin: "0 16px",
  textAlign: "center",
  color: "#333333",
  fontFamily: "Roboto-Medium",
}));
