import { React, useState } from "react";
import { experimentalStyled } from "@mui/material";
import HeaderMobile from "../components/Header/HeaderMobile";
import FooterMobile from "../components/Footer/FooterMobile";
import texture from "../assets/bg/texture-mobile.png";
import ellipse from "../assets/bg/ellipse.png";
import frameOneBG from "../assets/bg/frame-one.png";
import { Outlet } from "react-router-dom";

const LayoutMobileRoot = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  //   overflow: "hidden",
  width: "100%",
  background: "#fcfcfc",
}));

const FrameOneBG = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${frameOneBG}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  //   height:'100%'
}));

const Texture = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${texture}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "top",
}));

const Ellipse = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${ellipse}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
}));

const LayoutMobile = () => {
  return (
    <LayoutMobileRoot>
      <FrameOneBG
        sx={{
          backgroundSize: location.pathname === "/" ? "100% 150vw" : "100% 100%",
        }}
      >
        <Ellipse>
          <Texture>
            <HeaderMobile />
            <Outlet />
            <FooterMobile />
          </Texture>
        </Ellipse>
      </FrameOneBG>
    </LayoutMobileRoot>
  );
};

export default LayoutMobile;
