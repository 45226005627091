import React from "react";
import Landing from "./pages/Landing";
import Governance from "./pages/Governance";
import Layout from "./layout";

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/governance",
        element: <Governance />,
      },
    ],
  },
];

export default routes;
