import { Box, Button, experimentalStyled } from "@mui/material";

export const Wrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 600px)": {
    marginTop: "-30vw",
  },
  "@media (min-width: 1280px)": {
    marginTop: "-30vw",
  },
}));

export const Title = experimentalStyled("div")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "64px",
  lineHeight: "107%",
  textAlign: "center",
}));

export const HighLight = experimentalStyled("span")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "64px",
  lineHeight: "107%",
  background: "linear-gradient(90.81deg, #1480EB 9.6%, #3551E8 70.06%)",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

export const ButtonWrapper = experimentalStyled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "100%",
  height: "200px",
  marginTop: "80px",
}));

export const DocButton = experimentalStyled(Button)(() => ({
  background: "rgba(255, 255, 255, 0.7)",
  boxShadow: "0px 16px 24px rgba(0, 126, 253, 0.15)",
  border: "2px solid #8EA8C2",
  borderRadius: "31px",
  color: "#557697",
  fontFamily: "Roboto-Regular",
  fontSize: "18px",
  padding: "14px 24px",
  lineHeight: "100%",
  marginLeft: "32px",
}));
