import {
  Wrapper,
  Title,
  HighLight,
  ButtonWrapper,
  LaunchButton,
  DocButton,
  ProductDisplay,
  ProductDisplayContainer,
  Mask,
  Mask2,
} from "./style-mobile";
import { Box, useMediaQuery } from "@mui/material";
import arrowSvg from "../../assets/icon/arrow.svg";

function FrameOneMobile() {
  return (
    <Wrapper>
      <Title>
        <HighLight>Decentralized listings</HighLight> <br /> for information,{" "}
        <br />
        services, & more.
      </Title>
      <ButtonWrapper>
        <LaunchButton onClick={() => window.open(process.env.REACT_APP_APP)}>
          Launch App
        </LaunchButton>
        <DocButton onClick={() => window.open(process.env.REACT_APP_DOC)}>
          Read Our Developer Doc
          <Box component="img" src={arrowSvg} ml={0.8}></Box>
        </DocButton>
      </ButtonWrapper>
      <ProductDisplayContainer>
        <ProductDisplay src={process.env.REACT_APP_APP_PREVIEW_DASHBOARD} />
        <Mask />
        <Mask2 />
      </ProductDisplayContainer>
    </Wrapper>
  );
}

export default FrameOneMobile;
