import { Box, Button, experimentalStyled } from "@mui/material";

export const Wrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const Title = experimentalStyled("div")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "1.625rem",
  lineHeight: "107%",
  textAlign: "center",
  marginTop: "5vh",
}));

export const HighLight = experimentalStyled("span")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "1.625rem",
  lineHeight: "107%",
  background: "linear-gradient(90.81deg, #1480EB 9.6%, #3551E8 70.06%)",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

export const ButtonWrapper = experimentalStyled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "8vh",
}));

export const LaunchButton = experimentalStyled(Button)(() => ({
  background: "linear-gradient(115.84deg, #3991E9 -16.93%, #3774F0 117.9%)",
  boxShadow: "0px 16px 24px rgba(0, 126, 253, 0.15)",
  borderRadius: "31px",
  color: "white",
  fontFamily: "Roboto-Bold",
  fontSize: "18px",
  padding: "16px 24px",
  lineHeight: "100%",
  boxSizing: "content-box",
}));

export const DocButton = experimentalStyled(Button)(() => ({
  background: "rgba(255, 255, 255, 0.7)",
  boxShadow: "0px 16px 24px rgba(0, 126, 253, 0.15)",
  border: "2px solid #8EA8C2",
  borderRadius: "31px",
  color: "#557697",
  fontFamily: "Roboto-Regular",
  fontSize: "18px",
  padding: "14px 24px",
  lineHeight: "100%",
  marginTop: "15px",
}));

export const ProductDisplayContainer = experimentalStyled("Box")(() => ({
  position: "relative",
  width: "85%",
}));

export const ProductDisplay = experimentalStyled("iframe")(() => ({
  width: "100%",
  height: "690px",
  marginTop: "64px",
  boxShadow: "4px 16px 36px rgba(0, 76, 153, 0.1)",
  borderRadius: "8px",
  border: 0,
}));

export const Mask = experimentalStyled("Box")(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 2,
  width: "100%",
  height: "690px",
  boxShadow: "4px 16px 36px rgba(0, 76, 153, 0.1)",
  borderRadius: "8px",
  marginTop: "64px",
  backdropFilter: "blur(0.5px)",
}));

export const Mask2 = experimentalStyled("Box")(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 1,
  width: "100%",
  height: "690px",
  boxShadow: "4px 16px 36px rgba(0, 76, 153, 0.1)",
  borderRadius: "8px",
  marginTop: "64px",
  background: "#ccc",
  opacity: 0.1,
}));
