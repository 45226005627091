import { Wrapper, Title } from "./style";
import { Box, Link } from "@mui/material";
import roadMap from "../../assets/bg/road-map.svg";

function RoadMap() {
  return (
    <Wrapper>
      <Title>Road Map</Title>
      <Box component="img" src={roadMap} mt={3} width={1208}></Box>
    </Wrapper>
  );
}

export default RoadMap;
