import { Box, Grid, Link, experimentalStyled } from "@mui/material";

export const Wrapper = experimentalStyled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10vh 10vw 7vh",
}));

export const Copyright = experimentalStyled(Box)(() => ({
  fontSize: "0.8rem",
  color: "#333333",
}));

export const ContactLinkWrapper = experimentalStyled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ContactLink = experimentalStyled(Link)(() => ({
  display: "flex",
  alignItems: "center",
  color: "#333333",
  fontSize: "0.8rem",
  cursor: "pointer",
  marginTop: "0.8rem",
  margin: "1rem",
}));

export const Img = experimentalStyled("img")(() => ({
  marginRight: "10px",
}));
