import { Wrapper, Title, HighLight } from "./style-mobile";
import { Box } from "@mui/material";
import diagram from "../../assets/bg/diagram-frame-two-mobile.svg";

function FrameTwoMobile() {
  return (
    <Wrapper>
      <Title>
        A Decentralized ,<br /> Web3
        <HighLight> Marketplace.</HighLight>
      </Title>
      <Box component="img" src={diagram} mt={3} width={'85%'}></Box>
    </Wrapper>
  );
}

export default FrameTwoMobile;
