import {
  Wrapper,
  IntroduceWrapper,
  Title,
  HighLight,
  LearnMoreButton,
  Info,
} from "./style";
import { Box, Link } from "@mui/material";
import arrowSvg from "../../assets/icon/arrow-white.svg";
import diagram from "../../assets/bg/diagram-frame-six.png";

function FrameSixMobile() {
  return (
    <Wrapper>
      <IntroduceWrapper>
        <Title>
          A safer and reliable listing experience, enabled by
          <HighLight> community </HighLight>and DAO.
        </Title>
        <Info>
          ELS community is collectively managed by you and other
          <br /> members. Everyone participates in decision-making and
          <br /> ensures fairness and transparency.
        </Info>
        <LearnMoreButton onClick={() => window.open(process.env.REACT_APP_DOC)}>
          Learn More
          <Box component="img" src={arrowSvg} ml={0.8}></Box>
        </LearnMoreButton>
      </IntroduceWrapper>
      <Box component="img" src={diagram} mt={3} width={650}></Box>
    </Wrapper>
  );
}

export default FrameSixMobile;
