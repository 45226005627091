import { Box, Link, Button } from "@mui/material";
import {
  HeaderMobileContainer,
  Wrapper,
  LogoWrapper,
  FeatureWrapper,
  LaunchButton,
  TabLink,
  LogoTitle,
} from "./style-mobile";
import logo from "../../assets/logo/logo.png";
import dcSvg from "../../assets/icon/dc.svg";
import githubSvg from "../../assets/icon/github.svg";
import twitterSvg from "../../assets/icon/twitter.svg";
import menuSvg from "../../assets/icon/menu.svg";

const headerTabList = [
  { name: "About", link: "/", target: "_self" },
  { name: "Governance", link: "/governance", target: "_self" },
  { name: "ERC - 6150", link: process.env.REACT_APP_EIP6150, target: "_blank" },
  {
    name: "Docs",
    link: process.env.REACT_APP_DOC,
    target: "_blank",
  },
];

const headerLogoList = [
  { src: dcSvg, link: process.env.REACT_APP_DC },
  { src: twitterSvg, link: process.env.REACT_APP_TWITTER },
  { src: githubSvg, link: process.env.REACT_APP_GITHUB },
];

function HeaderMobile() {
  return (
    <Wrapper>
      <LogoWrapper>
        <Box component="img" src={logo} height={44} ml={0}></Box>
        <LogoTitle>Ethereum Listing Service</LogoTitle>
      </LogoWrapper>
      <Box component={"img"} src={menuSvg}></Box>
    </Wrapper>
  );
}

export default HeaderMobile;
