import { Box, Button, experimentalStyled } from "@mui/material";

export const Wrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "15vh",
}));

export const Title = experimentalStyled("div")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "1.4rem",
  lineHeight: "120%",
  textAlign: "center",
}));
