import { ThemeProvider } from "@mui/material";
import { createCustomTheme } from "./theme";
import { THEMES } from "./theme/constants";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import routesMobile from "./routes-mobile";
import useMediaQuery from "@mui/material/useMediaQuery";

const theme = createCustomTheme({
  direction: "ltr",
  responsiveFontSizes: true,
  roundedCorners: false,
  theme: THEMES.LIGHT,
});

function App() {
  const matches = useMediaQuery("(max-width:600px)");
  const content = useRoutes(matches ? routesMobile : routes);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>{content}</ThemeProvider>
    </div>
  );
}

export default App;
