import { Box, Button, experimentalStyled } from "@mui/material";

export const Wrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "3vh",
}));

export const IntroduceWrapper = experimentalStyled("div")(() => ({
  marginTop: "3vh",
  width: "85%",
}));

export const Title = experimentalStyled("div")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "1.4rem",
  lineHeight: "120%",
}));

export const HighLight = experimentalStyled("span")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "1.4rem",
  lineHeight: "120%",
  background: "#4773e8",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

export const Info = experimentalStyled(Box)(() => ({
  fontFamily: "Roboto-Light",
  fontSize: "0.5rem",
  lineHeight: "120%",
  margin: "20px 0",
}));

export const LearnMoreButton = experimentalStyled(Button)(() => ({
  background: "linear-gradient(115.84deg, #3991E9 -16.93%, #3774F0 117.9%)",
  boxShadow: "0px 16px 24px rgba(0, 126, 253, 0.15)",
  borderRadius: "31px",
  color: "white",
  fontFamily: "Roboto-Bold",
  fontSize: "0.8rem",
  padding: "8px 16px",
  lineHeight: "100%",
  boxSizing: "content-box",
}));
