import { Grid, Box, Link } from "@mui/material";
import {
  Wrapper,
  ContactLink,
  Copyright,
  ContactLinkWrapper,
  Img,
} from "./style-mobile";
import dcIcon from "../../assets/icon/dc.svg";
import twitterIcon from "../../assets/icon/twitter.svg";
import githubIcon from "../../assets/icon/github.svg";

const FooterMobile = () => {
  return (
    <Wrapper>
      <Copyright>©2023 Ethereum Listing Service</Copyright>
      <ContactLinkWrapper>
        <ContactLink href={process.env.REACT_APP_TWITTER} target="_blank">
          <Img src={twitterIcon}></Img>
          Twitter
        </ContactLink>
        <ContactLink href={process.env.REACT_APP_DC} target="_blank">
          <Img src={dcIcon}></Img>
          Discord
        </ContactLink>
        <ContactLink href={process.env.REACT_APP_GITHUB} target="_blank">
          <Img src={githubIcon}></Img>
          Github
        </ContactLink>
      </ContactLinkWrapper>
    </Wrapper>
  );
};

export default FooterMobile;
