import { React, useState } from "react";
import { experimentalStyled, Box } from "@mui/material";
import Header from "../components/Header/HeaderMobile";
import FrameOne from "../components/FrameOne";
import FrameTwo from "../components/FrameTwo";
import FrameThree from "../components/FrameThree";
import FrameFour from "../components/FrameFour";
import FrameFive from "../components/FrameFive";
import FrameSix from "../components/FrameSix";
import RoadMap from "../components/RoadMap";
import Contact from "../components/Contact";
import texture from "../assets/bg/texture.png";
import ellipse from "../assets/bg/ellipse.png";
import frameOneBG from "../assets/bg/frame-one.png";
import leftEllipse from "../assets/bg/ellipse-left.png";
import rightEllipse from "../assets/bg/ellipse-right.png";

const LandingRoot = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const FrameOneBG = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${frameOneBG}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
}));

const Texture = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${texture}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "top",
}));

const Ellipse = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${ellipse}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "initial",
  backgroundPosition: "-30% -10%",
}));

const LeftEllipse = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${leftEllipse}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
}));

const RightEllipse = experimentalStyled("div")(({ theme }) => ({
  backgroundImage: `url('${rightEllipse}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "right",
}));

const Landing = () => {
  return (
    <LandingRoot>
      {/* <FrameOneBG>
        <Ellipse>
          <Texture>
            <Header />
            <FrameOne />
          </Texture>
        </Ellipse>
      </FrameOneBG> */}
      <FrameOne />
      <FrameTwo />
      <RightEllipse>
        <FrameThree />
      </RightEllipse>
      <LeftEllipse>
        <FrameFour />
      </LeftEllipse>
      <RightEllipse>
        <FrameFive />
      </RightEllipse>
      <LeftEllipse>
        <FrameSix />
      </LeftEllipse>
      <RoadMap />
      <Contact />
    </LandingRoot>
  );
};

export default Landing;
