import { Grid, Box, Link } from "@mui/material";
import docSvg from "../assets/icon/doc.svg";
import tallySvg from "../assets/icon/tally.svg";
import dcSvg from "../assets/icon/dc-colorful.svg";

const governanceList = [
  {
    text: "Documentation",
    image: docSvg,
    link: "https://els-ethereum-listing-service.gitbook.io/untitled/",
    background: "#EBF5FF",
    color: "#5AA5EA",
    border: "1px solid rgba(90, 165, 234, 0.5)",
  },
  {
    text: "Vote on Tally",
    image: tallySvg,
    link: "https://www.tally.xyz/gov/els-governance-test2",
    background: "#EAFAF7",
    color: "#13CBB7",
    border: "1px solid rgba(0, 220, 196, 0.5)",
  },
  {
    text: "Chat on Discord",
    image: dcSvg,
    link: "https://discord.gg/bT4sRdXTr9",
    background: "#F2F3FF",
    color: "#686DEE",
    border: "1px solid rgba(104, 109, 238, 0.5)",
  },
];

const Governance = () => {
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        marginTop: "11vh",
      }}
    >
      <Box
        sx={{
          fontFamily: "Roboto-Bold",
          fontSize: "32px",
          lineHeight: "120%",
          color: "#000",
          textAlign: "center",
          marginBottom: "50px",
        }}
      >
        The ELS Governance Process
      </Box>
      <Grid display="flex">
        {governanceList.map((governance) => (
          <GovernanceBox props={governance}></GovernanceBox>
        ))}
      </Grid>
    </Grid>
  );
};

const GovernanceBox = ({
  props: { text, image, link, background, color, border },
}) => {
  return (
    <Link
      href={link}
      target="_blank"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
        width: "20vw",
        height: "20vh",
        background,
        color,
        border,
        borderRadius: "8px",
        margin: "0 8px",
        textDecoration: "unset",
      }}
    >
      <Box component="img" src={image}></Box>
      <Box
        sx={{
          fontFamily: "Roboto-Bold",
          fontSize: "22px",
          textDecoration: "unset",
          marginLeft: "20px",
        }}
        component="text"
      >
        {text}
      </Box>
    </Link>
  );
};

export default Governance;
