import { Grid, Box, Link } from "@mui/material";
import dcSvg from "../../assets/icon/dc-colorful.svg";
import twitterSvg from "../../assets/icon/twitter-colorful.svg";
import emailSvg from "../../assets/icon/email.svg";

const contactList = [
  {
    text: "Join our<br/>Discord Community",
    image: dcSvg,
    link: process.env.REACT_APP_DC,
    background: "rgba(135, 104, 238, 0.08)",
    color: "#8768EE",
    border: "1px solid rgba(135, 104, 238, 0.5)",
  },
  {
    text: "View our<br/>Twitter Posts",
    image: twitterSvg,
    link: process.env.REACT_APP_TWITTER,
    background: "rgba(90, 165, 234, 0.08)",
    color: "#5AA5EA",
    border: "1px solid rgba(90, 165, 234, 0.5)",
  },
  {
    text: "Email to<br/>Support Team",
    image: emailSvg,
    link: `mailto: ${process.env.REACT_APP_EMAIL}`,
    background: "rgba(55, 116, 240, 0.08)",
    color: "#3774F0",
    border: "1px solid rgba(55, 116, 240, 0.5)",
  },
];

const Contact = () => {
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        paddingBottom: "10vh",
        marginTop: "30vh",
      }}
    >
      <Box
        sx={{
          fontFamily: "Roboto-Bold",
          fontSize: "42px",
          lineHeight: "120%",
          color: "#000",
          textAlign: "center",
          marginBottom: "50px",
        }}
      >
        Be Part of Our Community
      </Box>
      <Grid display="flex">
        {contactList.map((contact) => (
          <ContactBox props={contact}></ContactBox>
        ))}
      </Grid>
    </Grid>
  );
};

const ContactBox = ({
  props: { text, image, link, background, color, border },
}) => {
  return (
    <Link
      href={link}
      target="_blank"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 20px",
        width: "320px",
        height: "140px",
        background,
        color,
        border,
        borderRadius: "8px",
        margin: "0 8px",
        textDecoration: "unset",
      }}
    >
      <Box component="img" src={image}></Box>
      <Box
        sx={{
          fontFamily: "Roboto-Bold",
          fontSize: "22px",
          textDecoration: "unset",
          marginLeft: "20px",
        }}
        component="text"
        dangerouslySetInnerHTML={{ __html: text }}
      ></Box>
    </Link>
  );
};

export default Contact;
