import { Box, Button, experimentalStyled } from "@mui/material";

export const Wrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20vh",
}));

export const Title = experimentalStyled("div")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "64px",
  lineHeight: "107%",
  textAlign: "center",
}));

export const HighLight = experimentalStyled("span")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "64px",
  lineHeight: "107%",
  background: "linear-gradient(90.81deg, #1480EB 9.6%, #3551E8 70.06%)",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

export const ButtonWrapper = experimentalStyled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "100%",
  marginTop: "80px",
}));

export const DocButton = experimentalStyled(Button)(() => ({
  background: "rgba(255, 255, 255, 0.7)",
  boxShadow: "0px 16px 24px rgba(0, 126, 253, 0.15)",
  border: "2px solid #8EA8C2",
  borderRadius: "31px",
  color: "#557697",
  fontFamily: "Roboto-Regular",
  fontSize: "18px",
  padding: "14px 24px",
  lineHeight: "100%",
  marginLeft: "32px",
}));

export const ProductDisplay = experimentalStyled("iframe")(() => ({
  width: "104vw",
  height: "2220px",
  "@media (max-width: 1364px)": {
    height: "2280px",
  },
  marginTop: "64px",
  boxShadow: "4px 16px 36px rgba(0, 76, 153, 0.1)",
  borderRadius: "8px",
  border: 0,
  transform: "scale(0.7)",
  transformOrigin: "top",
}));

export const ProductDisplayImg = experimentalStyled("img")(() => ({
  width: "104vw",
  // height: "2220px",
  marginTop: "64px",
  boxShadow: "4px 16px 36px rgba(0, 76, 153, 0.1)",
  borderRadius: "8px",
  border: 0,
  transform: "scale(0.7)",
  transformOrigin: "top",
}));

export const ProductDisplayContainer = experimentalStyled("Box")(() => ({
  position: "relative",
}));

export const Mask = experimentalStyled("Box")(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 2,
  width: "104vw",
  // height: "2220px",
  boxShadow: "4px 16px 36px rgba(0, 76, 153, 0.1)",
  borderRadius: "8px",
  marginTop: "64px",
  transform: "scale(0.7)",
  transformOrigin: "top",
  backdropFilter: "blur(1px)",
}));

export const Mask2 = experimentalStyled("Box")(() => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 1,
  width: "104vw",
  // height: "2220px",
  boxShadow: "4px 16px 36px rgba(0, 76, 153, 0.1)",
  borderRadius: "8px",
  marginTop: "64px",
  transform: "scale(0.7)",
  transformOrigin: "top",
  background: "#ddd",
  opacity: 0.1,
}));
