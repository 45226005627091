import { Box, Link, Button } from "@mui/material";
import {
  HeaderContainer,
  Wrapper,
  LogoWrapper,
  FeatureWrapper,
  LaunchButton,
  TabLink,
  LogoTitle,
} from "./style";
import logo from "../../assets/logo/logo.png";
import dcSvg from "../../assets/icon/dc.svg";
import githubSvg from "../../assets/icon/github.svg";
import twitterSvg from "../../assets/icon/twitter.svg";

const headerTabList = [
  { name: "About", link: "/", target: "_self" },
  { name: "Governance", link: "/governance", target: "_self" },
  { name: "ERC - 6150", link: process.env.REACT_APP_EIP6150, target: "_blank" },
  {
    name: "Docs",
    link: process.env.REACT_APP_DOC,
    target: "_blank",
  },
];

const headerLogoList = [
  { src: twitterSvg, link: process.env.REACT_APP_TWITTER },
  { src: dcSvg, link: process.env.REACT_APP_DC },
  { src: githubSvg, link: process.env.REACT_APP_GITHUB },
];

function Header() {
  return (
    <Wrapper>
      <LogoWrapper>
        <Box component="img" src={logo} height={50} ml={10}></Box>
        <LogoTitle>Ethereum Listing Service</LogoTitle>
      </LogoWrapper>
      <FeatureWrapper>
        {headerTabList.map((item) => (
          <TabLink href={`${item.link}`} target={`${item.target}`}>
            {item.name}
          </TabLink>
        ))}
        {headerLogoList.map((item, index) => (
          <Box
            component="img"
            src={`${item.src}`}
            onClick={() => window.open(item.link)}
            sx={{
              cursor: "pointer",
              margin: `0 9px 0 ${index === 0 ? "49px" : "9px"}`,
            }}
          ></Box>
        ))}
        <LaunchButton onClick={() => window.open(process.env.REACT_APP_APP)}>
          Launch App
        </LaunchButton>
      </FeatureWrapper>
    </Wrapper>
  );
}

export default Header;
