import { Button, Link, experimentalStyled } from "@mui/material";

export const Wrapper = experimentalStyled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  // height: 0,
  padding: "26px 30px",
}));

export const LogoWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  width: "fit-content",
  height: "fit-content",
  whiteSpace: "nowrap",
}));

export const LogoTitle = experimentalStyled("div")(() => ({
  fontFamily: "Roboto-Bold",
  fontSize: "23px",
  lineHeight: "24px",
}));

export const FeatureWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  height: "fit-content",
}));

export const TabLink = experimentalStyled(Link)(() => ({
  textDecoration: "unset",
  margin: "0 16px",
  textAlign: "center",
  color: "#333333",
  fontFamily: "Roboto-Medium",
}));

export const LaunchButton = experimentalStyled(Button)(() => ({
  background: "linear-gradient(115.84deg, #3991E9 -16.93%, #3774F0 117.9%)",
  boxShadow: "0px 16px 24px rgba(0, 126, 253, 0.15)",
  borderRadius: "31px",
  color: "white",
  fontFamily: "Roboto-Bold",
  fontSize: "18px",
  padding: "16px 24px",
  lineHeight: "100%",
  marginLeft: "32px",
  boxSizing: "content-box",
}));
