import React from "react";
import LandingMobile from "./pages/LandingMobile";
import GovernanceMobile from "./pages/GovernanceMobile";
import LayoutMobile from "./layout/LayoutMobile";

const routes = [
  {
    path: "/",
    element: <LayoutMobile />,
    children: [
      {
        path: "/",
        element: <LandingMobile />,
      },
      {
        path: "/governance",
        element: <GovernanceMobile />,
      },
    ],
  },
];

export default routes;
